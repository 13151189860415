import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { MakeIcon } from '@/components/atoms/MakeIcons/MakeIcon';
import { POPULAR_MAKES_EXTENDED } from '@/lib/constants';
import React, { FC, useCallback } from 'react';

export const ShopByPopularMakes: FC<{
  onClick: (make: string) => void;
  allMakes: string[];
}> = ({ onClick, allMakes }) => {
  const [viewAll, setViewAll] = React.useState(false);
  const handleViewAll = useCallback(() => {
    if (viewAll) {
      setViewAll(false);
      const element = document.getElementById('popular-makes-header');
      if (element) {
        setTimeout(() => {
          const elementPosition =
            element.getBoundingClientRect().top + window.scrollY;
          const offset = 100;

          window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth',
          });
        }, 50);
      }
    } else {
      setViewAll(true);
    }
  }, [viewAll]);

  const sortedAllMakes = POPULAR_MAKES_EXTENDED.concat(
    allMakes.filter((make: string) => !POPULAR_MAKES_EXTENDED.includes(make))
  );

  const makesList = viewAll ? sortedAllMakes : POPULAR_MAKES_EXTENDED;

  return (
    <div className="flex flex-col gap-xl pb-3xl m:pb-[64px]">
      <div
        id={'popular-makes-header'}
        className="flex w-full justify-center text-h2Regular text-neutral-900"
      >
        Popular makes
      </div>
      <div className="flex w-full justify-center">
        <div className="grid w-full grid-cols-2 justify-center gap-3xl m:max-w-[1038px] m:grid-cols-6">
          {makesList.map((make, i) => {
            return (
              <div
                key={`popular-makes-${make}-${i}`}
                onClick={() => onClick(make)}
                className="flex cursor-pointer flex-col justify-center gap-s"
              >
                <MakeIcon width={48} height={48} make={make} withOverlay />
                <div className="flex justify-center pt-[10px] text-body1Medium text-neutral-800">
                  {make}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div className="flex w-fit-content">
          <Button
            aria-label={'View all makes'}
            variant={ButtonVariants.Secondary}
            onClick={handleViewAll}
          >
            {viewAll ? 'View less' : 'View all makes'}
          </Button>
        </div>
      </div>
    </div>
  );
};
